import React from "react"

class Distribution extends React.Component {
  render() {
    return (
      <section
        id="distribution_page"
        className="hero distribution-page default-page"
      >
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-6 static-part is-mobile is-left padding-0">
              <div className="content">
                <div className="title">DISTRIBUTION</div>
                <span className="zigzag"></span>
                <p className="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-6 content-part is-right padding-0">
              <div className="single-box">
                <h4 className="title">Montenegro</h4>
                <div className="info">
                  <p>"ĆATIĆ CO" DOO</p>
                  <p>Industrijska bb, Bijelo Polje, Crna Gora</p>
                  <a href="tel: +387 123 4567">
                    <span>t:</span> +387 123 4567
                  </a>
                  <a href="mailto: caticco@t-com.me">
                    <span>e:</span> caticco@t-com.me
                  </a>
                  <a href="mailto: caticcobp@t-com.me">
                    <span>e:</span> caticcobp@t-com.me
                  </a>
                </div>
              </div>

              <div className="single-box">
                <h4 className="title">Croatia</h4>
                <div className="info">
                  <p>"TOMMY" doo</p>
                  <p>Domovinskog rata 20, Split, Hrvatska</p>
                  <a href="tel: +385 21 600 603">
                    <span>t:</span> +385 21 600 603
                  </a>
                  <a href="mailto: uprava@tommy.hr">
                    <span>e:</span> uprava@tommy.hr
                  </a>
                  <a href="www.tommy.hr">
                    <span>w:</span> www.tommy.hr
                  </a>
                </div>
                <br />
                <div className="info">
                                    <p>Plodine d.d.</p>
                                    <p>Ružićeva 29, Rijeka, Hrvatska</p>
                                    <a href="tel: +385 21 453 780"><span>t:</span> +385 21 453 780</a>
                                    <a href="www.plodine.hr"><span>w:</span> www.plodine.hr</a>
                                </div>
              </div>

              <div className="single-box">
                <h4 className="title">Bosnia and Herzegovina</h4>
                <div className="info">
                  <p>Venera d.o.o.</p>
                  <p>Blagoja Parovića bb, Banja Luka, RS / BiH</p>
                  <a href="tel: +387 (0)51 388 972">
                    <span>t:</span> +387 (0)51 388 972
                  </a>
                  <a href="tel: +387 (0)51 388 973">
                    <span>f:</span> +387 (0)51 388 973
                  </a>
                  <a href="www.venera.ba">
                    <span>w:</span> www.venera.ba
                  </a>
                </div>
              </div>

              <div className="single-box">
                <h4 className="title">Kosovo</h4>
                <div className="info">
                  <p>EMONA SH.P.K.</p>
                  <p>Ajet Gërguri nr. 42, Vushtrri 42000 - Kosovë</p>
                  <a href="tel: +381 (2)8 570 665">
                    <span>t:</span> +381 (2)8 570 665
                  </a>
                  <a href="mailto: info@emonagroup.com">
                    <span>e:</span> info@emonagroup.com
                  </a>
                  <a href="www.emonagroup.com">
                    <span>w:</span> www.emonagroup.com
                  </a>
                </div>
              </div>

              <div className="single-box">
                <h4 className="title">Palestine</h4>
                <div className="info">
                  <p>"Alvasari" DOO</p>
                  <p>Ustanička 64/10 Beograd, Srbija</p>
                  <a href="tel: +381 11 3442 356">
                    <span>t:</span> +381 11 3442 356
                  </a>
                  <a href="mailto: office@alvasari.rs">
                    <span>e:</span> office@alvasari.rs
                  </a>
                  <a href="www.alvasari.com">
                    <span>w:</span> www.alvasari.com
                  </a>
                </div>
              </div>

              <div className="single-box">
                <h4 className="title">Slovakia</h4>
                <div className="info">
                  <p>BONA</p>
                  <p>J.I.Bajzu 14, 971 01 PRIEVIDZA</p>
                  <a href="tel: +421465419634">
                    <span>t:</span> +421465419634
                  </a>
                  <a href="tel: +421465419634">
                    <span>f:</span> +421465419634
                  </a>
                  <a href="mailto: info@bonasweets.sk">
                    <span>e:</span> info@bonasweets.sk
                  </a>
                  <a href="mailto: info@bona.eu.sk">
                    <span>e:</span> info@bona.eu.sk
                  </a>
                  <a href="www.bona.eu.sk">
                    <span>w:</span> www.bona.eu.sk
                  </a>
                </div>
              </div>
              
              <div className="single-box">
                                <h4 className="title">Austria</h4>
                                <div className="info">
                                    <p>Brajlovic Gmbh</p>
                                    <p>Zetschegasse 17 / Top 5 , 1230 Wien, Austria</p>
                                    <a href="www.brajlovic.at"><span>w:</span> www.brajlovic.at</a>
                                </div>
                           </div>
                           
                           <div className="single-box">
                                <h4 className="title">Germany</h4>
                                <div className="info">
                                    <p>Natura Gmbh</p>
                                    <p>Wiesenstrasse 33,Schorndorf 73614,Germany</p>
                                    <a href="www.natura-food.de"><span>w:</span> www.natura-food.de</a>
                                </div>
                           </div>
                           
                           <div className="single-box">
                                <h4 className="title">Holland</h4>
                                <div className="info">
                                    <p>Edex food groothandel BV</p>
                                    <p>Riethil 3-5, 4825 AP Breda, Nederland</p>
                                    <a href="www.edexfood.com"><span>w:</span> www.edexfood.com</a>
                                </div>
                           </div>
                           
                           <div className="single-box">
                                <h4 className="title">Sweden</h4>
                                <div className="info">
                                    <p>Plivit Trade AB</p>
                                    <p>Lucernavägen 10, 593 50  Västervik, Sweden</p>
                                    <a href="www.plivit-trade.com"><span>w:</span> www.plivit-trade.com</a>
                                </div>
                           </div>
                           
                           <div className="single-box">
                                <h4 className="title">USA</h4>
                                <div className="info">
                                    <p>A.B. Company, Inc.</p>
                                    <p>6525 N. Proesel Ave.</p>
                                    <p>Lincolnwood,IL,60712</p>
                                    <a href="www.abcoimport.com"><span>w:</span> www.abcoimport.com</a>
                                </div>
                           </div>
            </div>
            {/* PRODUCTS PART END */}

            <div className="column is-6 static-part is-desktop is-left padding-0">
              <div className="content">
                <div className="title">DISTRIBUTION</div>
                <span className="zigzag"></span>
                <p className="text">
                  Our products are on the shelves of many supermarkets in the
                  region and around the world. This is the responsibility of our
                  distributors.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default Distribution
